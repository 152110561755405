import merge from 'lodash.merge'
import ar from './ar'

const US = 'US Academy'

export default merge(ar, {
  site: {
    title: US,
    sub_title: 'اصقل مهاراتك العلميه مع\n US Academy',
    description: '',
  },
  ui: {
    about_title: US,
  },
  nav: {
    explore_courses: 'الكورسات',
  },
  home: {
    about: {
      title: '',
      sub_title: ' ',
      text1: 'منصه  تعليميه الكترونيه توفر الموارد و الأدوات التعليميه الحديثة بصوره سهله و بسيطه لتوفر فرص التعلم و التطور الشخصي في أي وقت و أي مكان.',
      text2: '',
    },
  },
  footer: {
    description: ` تعلم وتطور مع منصتنا التعليمية التي تقدم محتوى متميزًا وأدوات حديثة بأسلوب بسيط وسهل.`,
  },
})
