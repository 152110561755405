const showTermsMobileViewQueryParam = 'showTermsMobileView'

const qParams = new URL(document.location.toString()).searchParams

if (!qParams.has(showTermsMobileViewQueryParam) || qParams.get(showTermsMobileViewQueryParam)?.toLowerCase() !== 'true') {
  /* eslint-disable */
  console.log('setting zoho salesiq US');

  // zoho salesiq brand: UA
  window.$zoho=window.$zoho || {};$zoho.salesiq = $zoho.salesiq ||
  {widgetcode: "siqfc8e3644b2c1ac58ecd60610ee7b261a82b56bdb0819c037ddf86fc366361aa3", values:{},ready:function(){}};
  var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);

  window.$zoho.salesiq.ready = function () {
    window.$zoho.salesiq.chat.logo("https://i.imgur.com/NkQ9Tfy.png");
  }
}
